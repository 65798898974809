@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

* {
  margin: 0;
  padding: 0;
}


html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  color-scheme: dark;
}

.bg {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.homeTextContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 5px;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
}

.homeText {
  height: 100%;
  animation: slideInFromLeft .8s;
  color: #F2F0F0;
}

.homeText2 {
  animation: slideInFromRight .8s;
  color: #F2F0F0;
}

.specialHomeText {
  color: #EEF280;
}

.headingLink {
  animation: slideInFromBottom .5s forwards;
  animation-delay: 1s;
  opacity: 0;
  margin-top: 15px;
  color: #F2F0F0;
  font-size: 2rem;
  width: fit-content;
  text-decoration: none;
}

@keyframes slideInFromLeft {
  0% { 
    opacity: 0;
    transform: translateX(-50px); 
  }
  100% {
    opacity: 1;
    transform: translateX(0); 
  }
}

@keyframes slideInFromRight {
  0% { 
    opacity: 0;
    transform: translateX(50px); 
  }
  100% {
    opacity: 1;
    transform: translateX(0); 
  }
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.workImg {
  padding-top: 2px;
  padding-left: 10px;
  transition: opacity 0.3s ease-in-out 0.3s;
  opacity: 1;
}

.viewWorkBtn:hover .workImg {
  opacity: 0;
}

.viewWorkBtn:hover .workImgYellow {
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.3s;
}

.workImgYellow {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding-top: 2px;
  padding-left: 10px;
}

.viewWorkBtn,
.viewWorkBtn:after,
.viewWorkBtn:before {
  border: 0 solid;
  box-sizing: border-box;
}
.viewWorkBtn {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background: none;
  color: #F2F0F0;
  cursor: pointer;
  font-family: 'Oswald', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.3rem;
  line-height: 1;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  animation: slideInFromBottom .5s forwards;
  animation-delay: 1s;
  opacity: 0;
  margin-top: 13px;
}

.viewWorkBtn {
  --neon: #F1BDF2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 900;
  -webkit-mask-image: none;
  outline: 4px solid #EEF280;
  outline-offset: -4px;
  overflow: hidden;
  padding-top: .8rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 1.6rem;
  position: relative;
  transition: 0.2s linear 0.1s;
}

.viewWorkBtn:hover {
  background: var(--neon);
  box-shadow: 0 0 5px var(--neon), 0 0 25px var(--neon), 0 0 50px var(--neon),
    0 0 100px var(--neon);
  color: #EEF280;
  outline-color: transparent;
  transition: 0.2s linear 0.6s;
}

.viewWorkBtn span {
  display: block;
  inset: 0;
  position: absolute;
}

.viewWorkBtn .top {
  border-top: 4px solid var(--neon);
  opacity: 0;
  transform: translateX(calc(-100% + var(--progress, 0%)));
  transition: none;
}

.viewWorkBtn:hover .top {
  --progress: 100%;
  opacity: 1;
  transition: transform 0.2s linear;
}

.viewWorkBtn .right {
  border-right: 4px solid var(--neon);
  opacity: 0;
  transform: translateY(calc(-100% + var(--progress, 0%)));
  transition: none;
}

.viewWorkBtn:hover .right {
  --progress: 100%;
  opacity: 1;
  transition: transform 0.2s linear 0.2s;
}

.viewWorkBtn .bottom {
  border-bottom: 4px solid var(--neon);
  opacity: 0;
  transform: translateX(calc(100% - var(--progress, 0%)));
  transition: none;
}

.viewWorkBtn:hover .bottom {
  --progress: 100%;
  opacity: 1;
  transition: transform 0.2s linear 0.4s;
}

.viewWorkBtn .left {
  border-left: 4px solid var(--neon);
  opacity: 0;
  transform: translateY(calc(100% - var(--progress, 0%)));
  transition: none;
}

.viewWorkBtn:hover .left {
  --progress: 100%;
  opacity: 1;
  transition: transform 0.2s linear 0.6s;
}

.viewWorkBtn > .arrow {
  width: 8px;
  height: 8px;
  border-right: 3px solid;
  border-bottom: 3px solid;
  position: relative;
  transform: rotate(45deg);
  margin: 0 8px;
  transition: all 1s ease;
}

.viewWorkBtn > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all 1s ease;
  content: "";
  right: 0;
}

.viewWorkBtn:hover > .arrow {
  transform: rotate(45deg) translate(4px, 4px);
  border-color: text-hover-color;
  margin-bottom: 6px;
}

.viewWorkBtn:hover > .arrow::before {
  opacity: 1;
  width: 14px;
  height: 3px;
  margin-right: 1px;
}

#down {
  margin-top: 100%;
  padding-bottom: 25%;
  opacity: 0;
}

.section3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
}

.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 100px;
  height: 55px;
  overflow: hidden;
  position: sticky;
  top: 0px;
  background-color: #262626;
  z-index: 1000;
  gap: 25px;
}

.navBtn,
.navBtn:after,
.navBtn:before {
  border: 0 solid;
  box-sizing: border-box;
}

.navBtn {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  color: #fff;
  cursor: pointer;
  font-family: 'Oswald', 'Open Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  margin-bottom: 10px;
}

.navBtn {
  --thickness: 4px;
  background: none;
  box-sizing: border-box;
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% + var(--thickness)),
    0 calc(100% + var(--thickness))
  );
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% + var(--thickness)),
    0 calc(100% + var(--thickness))
  );
  display: block;
  font-weight: 900;
  -webkit-mask-image: none;
  padding: 2px 0;
  position: relative;
  text-transform: uppercase;
}

.navBtn:before {
  background: #fff;
  bottom: calc(var(--thickness) * -1);
  content: "";
  display: block;
  height: var(--thickness);
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.navBtn:hover {
  color: #EEF280;
}

.navBtn:hover:before {
  -webkit-animation: slide 0.5s ease;
  animation: slide 0.5s ease;
  background: #EEF280;
}

@-webkit-keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  51% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.active:before {
  background: #EEF280;
}

.active {
  color: #EEF280;
}

.sectionTitle {
  height: fit-content;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 3.5rem;
  position: relative;
  z-index: 2;
}

.sectionTitleBack {
  background: #F1BDF2;
  height: 15px;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 56%;
  left: 12%;
}

.project {
  display: flex;
  justify-content: left;
  width: 95vw;
  height: calc(100vw * (9/16));
  max-height: calc(70em * (9/16));
  max-width: 1970px;
  padding-bottom: 100px;
  padding-left: 5vw;
}

.projectRight {
  display: flex;
  justify-content: right;
  width: 95vw;
  height: calc(100vw * (9/16));
  max-height: calc(70em * (9/16));
  max-width: 1970px;
  padding-bottom: 100px;
  padding-right: 5vw;
}

.projectImageContainer {
  flex: .65;
  display: flex;
  align-items: center;
  max-width: 1000px;
  max-height: 700px;
  background-color: #262626;
  width: 100%;
}

.projectImageContainerRight {
  flex: .65;
  display: flex;
  justify-content: right;
  align-items: center;
  max-width: 1000px;
  max-height: 700px;
  background-color: #262626;
}

.projectImage {
  width: 85%;
  height: 75%;
  max-width: 775px;
  max-height: 475px;
  object-fit: fill;
}

.projectInfo {
  flex: .3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin-left: -55px;
  margin-bottom: 200px;
  overflow: auto;
  gap: 25px;
  
}

.projectInfoRight {
  flex: .3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin-right: -95px;
  margin-bottom: 200px;
  padding-left: 15px;
  overflow: auto;
  gap: 25px;
  z-index: 1;
}

.projectTitle {
  font-size: 2rem;
  width: 70%;
}

.projectDescrip {
  font-size: 1.3rem;
  overflow: auto;
  width: 80%;
}

.fill {
  position: relative;
  display:block;
  height: 45px;
  width: fit-content;
  padding: 5px;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 3px;
  color: white;
  border-bottom: 3px #F1BDF2 solid;
  border-left: none;
  border-right: none;
  border-top: none;
  background: none;
  z-index: 1;
  cursor: pointer;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
  transition:         0.08s ease-in;
  -o-transition:      0.08s ease-in;
  -ms-transition:     0.08s ease-in;
  -moz-transition:    0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.fill:hover {
  color: white;
}

.fill:before {
  content: "";
  position: absolute;
  background: #F1BDF2;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.09s ease-in;
}

.fill:hover:before {
  top: 0;
}

.fillA {
  text-decoration: none;
}

.section3Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.section3 > p {
  font-size: 1.2rem;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding-top: 50px;
  padding-bottom: 100px;
}

.contactForm > input {
  background-color: #262626;
  border: none;
  height: 40px;
  outline: none;
  caret-color: #F2F0F0;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
}

.contactForm > input[type="text"], .contactForm > input[type="email"] {
  padding-left: 25px;
  color: white;
  font-size: 1rem;
}

.contactForm > textarea {
  background-color: #262626;
  border: none;
  height: 90px;
  padding-left: 25px;
  color: white;
  line-height: 1;
  padding-top: 10px;
  font-size: 1rem;
  resize: none;
  outline: none;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
}

.contactForm > input::placeholder {
  color: #A6A6A6;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.contactForm > textarea::placeholder {
  color: #A6A6A6;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.contactForm > .fill {
  align-self: flex-end;
}

.section3 > .sectionTitle {
  padding-top: 0px;
  padding-bottom: 50px;
}

.section3 > .sectionTitle > .sectionTitleBack {
  top: 42%;
  left: 12%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100vw;
  background-color: #262626;
}

.footerBtnContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  align-items: center;
  row-gap: 15px;
}

.githubImg {
  height: 33px;
  width: 33px;
  justify-self: flex-start;
  transition: all 0.3s ease;
  cursor: pointer;
}

.githubImgA {
  height: 35px;
  width: 35px;
  justify-self: flex-start;
}

.emailImg {
  height: 35px;
  width: 35px;
  justify-self: flex-end;
  transition: all 0.3s ease;
  cursor: pointer;
}

.emailImgA {
  height: 35px;
  width: 35px;
  justify-self: flex-end;
}

.githubImg:hover, .emailImg:hover {
  transform: scale(1.2);
}

.footerText {
  grid-column-start: 1;
  grid-column-end: 3;
  font-family: 'Oswald', 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: .7rem;
  letter-spacing: .5px;
}

.center-con {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  position: absolute;
  top: -95%;
  left: 24%;
  cursor: pointer;
}

.round {
  position: relative;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.round > span {
  z-index: 999;
  height: 2px;
  width: 12px;
  background: #fff;
  transition: 0.4s ease;
}

.round > span:first-child {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 26%;
  bottom: 35%;
}

.round > span:nth-child(2) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 44%;
  bottom: 35%;
}

.round > span:nth-child(3) {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 26%;
  bottom: 54%;
}

.round > span:nth-child(4) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 44%;
  bottom: 54%;
}

.round:hover span:nth-child(1) {
  transform: rotate(-135deg);
}

.round:hover span:nth-child(2) {
  transform: rotate(135deg);
}

.round:hover span:nth-child(3) {
  transform: rotate(225deg);
}

.round:hover span:nth-child(4) {
  transform: rotate(-225deg);
}

@media (min-width: 1500px) {
  .projectInfoRight {
    margin-right: -250px;
  }
}

@media (max-width: 1050px) {
  .sectionTitleBack {
    height: 13px;
    width: 75%;
    top: 60%;
    left: 20%;
  }
  .navBar {
    justify-content: center;
    padding: 0;
    font-size: 1rem;
  }
  .section2 > h2 {
    font-size: 3.5rem;
    padding: 30px;
  }
  .projectInfo, .projectInfoRight {
    margin-bottom: 0;
    padding-left: 5px;
    gap: 5%;
  }
  .projectTitle {
    font-size: 1.8rem;
    width: 80%;
  }
  .projectDescrip {
    font-size: 1.2rem;
  }
  .fill {
    font-size: 1.2rem;
    padding: 0px;
    height: fit-content;
  }
  .section3 > .sectionTitle > .sectionTitleBack {
    height: 13px;
    width: 90%;
    top: 43%;
    left: 15%;
  }
}

@media (max-width: 599px) {
  .homeTextContainer {
    font-size: 2.3rem;
  }
  .viewWorkBtn {
    font-size: 1rem;
    padding-top: .6rem;
    padding-bottom: .8rem;
    padding-left: 1.8rem;
    padding-right: 1.4rem;
  }
  .navBar {
    justify-content: center;
    padding: 0;
    height: 40px;
  }
  .navBtn {
    font-size: .8rem;
  }
  .section2 > h2 {
    font-size: 2.5rem;
    padding: 30px;
    padding-top: 45px;
  }
  .projectRight {
    padding-bottom: 55px;
  }
  .sectionTitleBack {
    height: 10px;
    width: 65%;
    top: 65%;
    left: 25%;
  }
  .projectInfo, .projectInfoRight {
    margin-bottom: 0;
    gap: 15px;
    background-color: rgba(26, 26, 26, 0.8);
    height: fit-content;
    align-self: center;
  }
  .projectTitle {
    font-size: .9rem;
    width: 80%;
  }
  .projectDescrip {
    font-size: .7rem;
    width: auto;
  }
  .fill {
    font-size: .7em;
    padding: 0px;
    height: fit-content;
  }
  .section3Container {
    width: 85%;
    font-size: .8rem;
  }
  .section3 > h2 {
    font-size: 2.6rem;
    padding-bottom: 0px;
  }
  .section3 > .sectionTitle {
    padding: 25px;
  }
  .section3 > .sectionTitle > .sectionTitleBack {
    height: 10px;
    width: 67%;
    top: 62%;
    left: 26%;
  }
  .contactForm > .fill {
    font-size: .8rem;
  }
  .contactForm {
    padding-top: 25px;
    padding-bottom: 50px;
  }
  .contactForm > textarea::placeholder, .contactForm > input::placeholder {
    font-size: .7rem;
  }
  .contactForm > input[type="text"], .contactForm > input[type="email"] {
    font-size: .7rem;
  }
  .round {
    width: 34px;
    height: 34px;
  }
  .round > span {
    height: 2px;
    width: 10px;
    background: #fff;
    transition: 0.4s ease;
  }
  .githubImg, .emailImg {
    height: 30px;
    width: 30px;
  }
}

